import React, { Component } from "react"
// import { Link } from "gatsby"
import Slider from "react-slick"
//import responsive from './slider-resonsive'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ourSolutions } from "../../../content/homepage/homeContent"

class SolutiiSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    const settings = {
      accessibility: true,
      adaptiveHeight: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
    }
    return (
      <>
        <Slider
          {...settings}
          className="primary"
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
        >
          {ourSolutions.map((item, index) => (
            <div className="section-full box-about-list" key={index}>
              <h4 className="th-solutii-slider-title">{item.titlu}</h4>
              <div className="row spno">
                <div
                  className="col-lg-6 col-md-12"
                  style={{ backgroundColor: "var(--th-secondary-color)" }}
                >
                  <div className="max-w700 m-auto p-tb50 p-lr20">
                    <div className="max-w600 m-auto">
                      <div className="text-white">
                        <h2 style={{ fontSize: 25 }}>{item.subTitlu}</h2>
                      </div>
                    </div>

                    {/* Items wrapper */}
                    <div className="solutii-slider-items__wrapper">
                      {item.content.map((solutie, id) => (
                        <div className="max-w600 m-auto" key={id}>
                          <ul className="list-check reverse list-box ">
                            <li
                              style={{
                                color: "white",
                                background: "var(--th-tertiary-color)",
                                fontSize: 13,
                                fontWeight: "medium",
                                fontFamily: "Poppins",
                              }}
                            >
                              {solutie}
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>

                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <img src={item.image.default} alt="" className="img-cover" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default SolutiiSlider
