import React from "react"
import { Link } from "gatsby"
import "../../css/templete.min.css"
import "../../components/Home/home.css"
import useFetch from "../../utils/fetch"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Circles } from "react-loader-spinner"

const qs = require("qs")
const MAX_EXCERPT_CHARS = 58

const NoutatiCard = () => {
  // prepare blogposts request's query
  const query = qs.stringify(
    {
      sort: ["date:desc"],
      populate: { FeatureImage: { fields: ["formats", "url"] } },
      pagination: {
        limit: 3,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )

  const { loading, error, strapi_response } = useFetch(
    `${process.env.STRAPI_ENDPOINT}/posts?${query}`
  )

  if (loading)
    return (
      <div className="spinner">
        <Circles color="#00BFFF" height={80} width={80} />
      </div>
    )
  if (error) return <p>Nu exista noutati....</p>

  // console.log('ce se intampla pe aicisea --- ', strapi_response)

  return (
    <>
      {strapi_response !== undefined ? strapi_response?.data?.map((item, id) => {
        const current = new Date(item?.attributes?.date)
        const month = current.toLocaleString("default", { month: "long" })
        const year = current.getFullYear()

        const date = `${current.getDate()} ${month} ${year}`

        const postImg =
          `${process.env.STRAPI_BACKEND}` +
          item?.attributes?.FeatureImage?.data?.attributes?.url

        let postExcerpt = ""
        if (item?.attributes?.ShortDescription?.length > MAX_EXCERPT_CHARS) {
          postExcerpt =
            item?.attributes?.ShortDescription.substring(0, MAX_EXCERPT_CHARS) +
            "..."
        } else {
          postExcerpt = item?.attributes?.ShortDescription
        }
        
        const title = item?.attributes?.Title.length > 48 ? item?.attributes?.Title?.substring(0, 47) + '...': item?.attributes?.Title
        console.log('title --- ', title)
        return (
          <div
            key={id}
            className="col-lg-4 col-md-6 wow fadeInUp th-noutati__responsive"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
              <div className="dlab-post-media th-img-responsive__wrapper dlab-img-effect">
                <Link to={`/cercetare/${item.attributes.postSlug}`}>
                  <img
                    src={postImg}
                    alt={`Linda Postare Recenta - ${item?.attributes?.Title}`}
                  />
                </Link>
              </div>
              <div className="dlab-info p-a20 border-1 bg-white">
                <div className="dlab-post-meta">
                  <ul>
                    <li className="post-date">
                      {" "}
                      <strong>{date}</strong>
                    </li>
                    <li className="post-author">
                      {" "}
                      By <Link to="#">{item.attributes.author}</Link>
                    </li>
                  </ul>
                </div>
                <div className="dlab-post-title">
                  <h4 className="post-title">
                    <Link to={`/cercetare/${item.attributes.postSlug}`}>
                      {title}
                    </Link>
                  </h4>
                </div>
                <div className="dlab-post-text">
                  <p>{postExcerpt}</p>
                </div>
                <div className="dlab-post-readmore">
                  <Link
                    to={`/cercetare/${item.attributes.postSlug}`}
                    title="Vezi mai mult"
                    rel="bookmark"
                    className="site-button btnhover16"
                  >
                    Mai mult
                    <i className="ti-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      }) : null}
    </>
  )
}

export default NoutatiCard
