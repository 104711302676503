import * as React from "react"
import Layout from "../markup/layout/layout"
import Seo from "../markup/layout/seo"
import Home from "../components/Home/Home"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Pagina Principala" />
      <Home />
    </Layout>
  )
}

export default IndexPage
