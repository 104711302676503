import React, { Fragment, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
// import events from '../../resources/events'
import { Calendar, Views, DateLocalizer, momentLocalizer } from 'react-big-calendar'
// import DemoLink from '../../DemoLink.component'
// // Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
// // Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './style.css'
import { ro } from 'date-fns/locale'
moment.locale('ro')

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer


const DragAndDropCalendar = withDragAndDrop(Calendar)
// const localizer = DragAndDropCalendar.momentLocalizer(moment) // or globalizeLocalizer


// what you must have is this
const events = [
  {
    title: 'Sedinta',
    start: new Date('2023-02-12T13:45:00-05:00'),
    end: new Date('2023-02-12T14:00:00-05:00')
  }
]

const messages = {
  allDay: 'Toată ziua',
  previous: 'Înapoi',
  next: 'Înainte',
  today: 'Astăzi',
  month: 'Lună',
  week: 'Săptămână',
  day: 'Zi',
  agenda: 'Agendă',
  date: 'Dată',
  time: 'Timp',
  event: 'Eveniment',
  dayNames: ['Duminică', 'Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă'],
  dayNamesShort: ['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sâ'],
  monthNames: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'],
};

export default function MyDragAndDropCalendar({ formData, setFormData }) {
  const [myEvents, setMyEvents] = useState(events)
  const [view, setView] = useState('month')

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true
      }

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end, allDay }]
      })

      // update current selected date
      setFormData({
        ...formData,
        dateTime: start
      })
    },
    [setMyEvents]
  )

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end }]
      })

      // update current selected date
      setFormData({
        ...formData,
        dateTime: start
      })
    },
    [setMyEvents]
  )

  const defaultDate = useMemo(() => {
    console.log('test useMemo')
    const newDefaultDate = myEvents[0].start
    return newDefaultDate
  }, [view])

  const handleSelect = ({ start }) => {
    const newEvent = {
      title: "Sedinta",
      start: start,
      end: start,
    };
    setMyEvents([newEvent]);

    console.log('step 3')
    // save selected date
    setFormData({
      ...formData,
      dateTime: newEvent.start
    })
  };


  // const handleView = (newView) => {
  //   // const newEvent = {
  //   //   title: "Sedinta",
  //   //   start: new Date('2023-02-24T13:45:00-05:00'),
  //   //   end: new Date('2023-02-24T13:45:00-05:00'),
  //   // };
  //   // setMyEvents([newEvent]);


  //   // if new view, remember it
  //   // setView(newView)
  // }

  return (
    <div className="height600">
      <DragAndDropCalendar
        style={{ height: 400 }}
        selectable
        // onView={handleView}
        onSelectSlot={handleSelect}
        defaultDate={defaultDate}
        defaultView={view}
        events={myEvents}
        localizer={localizer}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        popup
        resizable
        culture='ro'
        messages={messages}
      />
    </div>
  )
}
MyDragAndDropCalendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
}