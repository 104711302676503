import React, { Component } from "react"

import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import "../../css/slider-animations.css"
import "../../css/homeslider.css"
import { Link } from "gatsby"


class HomeBannerSlider extends Component {
  render() {
    const sliderContent = this.props.data
    const { btnText, hideBtn, show2Btn, btn2Text, contentWrapperCls } =
      this.props
    const contentCls = contentWrapperCls || ""
    const btnCls = this.props.btnCls || ""
    const btnCls2 = this.props.btnCls2 || ""

    return (
      <>
        <Slider className="slider-wrapper home-slider2 th__home-banner">
          {sliderContent.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../images/background/shutterstock_1927041545.png")
                    .default +
                  ")",
              }}
            >
              <div className={`inner ctas-wrapper ${contentCls}`}>
                {item.title && <h1>{item.title}</h1>}
                {item.description && <p>{item.description}</p>}

                {hideBtn ? (
                  ""
                ) : show2Btn ? (
                  <div className="banner-cta-btns__home">
                    <Link to="/despre-noi">
                      <button className={btnCls}>{btnText}</button>
                    </Link>
                    <Link to="/devino-partener">
                      <button className={btnCls2}>{btn2Text}</button>
                    </Link>
                  </div>
                ) : (
                  <Link to="/despre-noi">
                    <button className={btnCls}>{btnText}</button>
                  </Link>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default HomeBannerSlider
