import React, { Component, useState } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
//import responsive from './slider-resonsive'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import CalendarModal from "../../components/Modals/CalendarModal"
import "./solutii-demo-slider.css"

class SolutiiDemoSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: -1
    };

    // methods references
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleLink = this.handleLink.bind(this)

  }

  handleLink = (event, hasModal, modalId) => {
    if (hasModal) {
      event.preventDefault()
      this.showModal(modalId)
    }
  }

  showModal = (modalId) => {
    console.log('open calendar modal!!!!!!')
    this.setState({ show: modalId });
  }

  closeModal = () => {
    this.setState({ show: -1 });
  }

  render() {
    const settings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }


    // console.clear()

    return (
      <>
        <Slider
          {...settings}
          className="img-carousel-dots-nav btn-style-1 icon-2 solutii-demo-slider"
        >
          {this.props.data.map((item, id) => {
            console.log('title -- ', item.modalTitle, id)
            return (
              <div className="item" key={id}>
                <div className="dlab-box project-bx">
                  <div className="dlab-media radius-sm  radius-top-no">
                    <a href={item.link} onClick={(event) => this.handleLink(event, item.hasModal, id)}>
                      <img src={item.image} alt="" />
                    </a>
                  </div>

                  <div className="dlab-info">
                    <h4 className="dlab-title">
                      <a href={item.link} onClick={(event) => this.handleLink(event, item.hasModal, id)}>{item.projectName}</a>
                    </h4>
                    <a href={item.link} onClick={(event) => this.handleLink(event, item.hasModal, id)}>
                      <button
                        className="btn"
                        type="button"
                        style={{
                          width: "100%",
                          backgroundColor: "var(--th-secondary-color)",
                          color: "var(--th-primary-color)",
                        }}
                      >
                        Vezi detalli
                      </button>
                    </a>
                  </div>
                </div>

                {item.hasModal ? <CalendarModal solutionName={item.projectName} modalId={id} show={this.state.show} handleClose={this.closeModal} title={item.modalTitle} /> : null}
              </div>
            )
          })}
        </Slider>
      </>
    )
  }
}
export default SolutiiDemoSlider
