import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MyDragAndDropCalendar from '../../MyDragAndDropCalendar';
import './style.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Circles } from "react-loader-spinner"

const axios = require("axios").default

function CalendarModal({ show, handleClose, title, modalId, solutionName }) {
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    title: solutionName,
    email: "",
    description: "",
    location: "",
    duration: {
      type: "15-min",
      value: 15
    },
    dateTime: "",
  })

  const durations = [15, 30]

  // const [selectedDates, setSelectedDates] = useState([]);

  console.log("step2 ---> ", formData.dateTime)

  const resetForm = () => {
    setFormData({
      title: solutionName,
      email: "",
      description: "",
      location: "",
      duration: {
        type: "15-min",
        value: 15
      },
      dateTime: ""
    })
  }

  const handleDuration = (duration, type = undefined) => {
    setFormData({
      ...formData,
      duration: {
        type: duration > 60 || type === 'custom' ? 'custom' : `${duration}-min`,
        value: duration
      },
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // submit form data to backend
    console.log('submitted Form data --- ', formData)

    // send data to backend
    await sendOffer({ ...formData })

    resetForm()

    // close the modal
    handleClose();
  };

  const sendOffer = async (formData) => {
    setLoading(true)

    console.log('test step2')
    await axios
      .post(`${process.env.STRAPI_ENDPOINT}/offers`, {
        data: {
          topic: formData.title,
          attendant: formData.email,
          startTime: formData.dateTime,
          duration: formData.duration.value,
          description: formData.description
        }
      }, {
        headers: {
          Authorization: `Bearer ${process.env.API_KEY}`,
        }
      })
      .then(response => {
        console.log('response --- ', response)
        alert('Cererea a fost trimisa cu succes! Veti primi un mesaj pe email cu detaliile sedintei de zoom deindata ce cererea de oferta este aprobata!')
      })
      .catch((err) => {
        console.log('axios offer err -- ', err.response.data.error.message)

        if (err.response.data.error.message.includes('contains html code'))
          alert('Nu introduceti cod html in campuri!')
        else if (err.response.data.error.message.includes('contains characters with special meaning in SQL'))
          alert('Nu introduceti caractere cu semnificatie speciala in SQL!!')

      })

    setLoading(false)
  }



  const isCustomDuration = 'custom' === formData.duration.type


  console.log(formData)

  return (
    <>
      <Modal show={show === modalId} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading ?
            // show loading animation
            <div className="spinner">
              <Circles color="#00BFFF" height={80} width={80} />
            </div> :
            // else show form
            <form onSubmit={handleSubmit}>
              {/* Title */}
              <div className="form-group">
                <label htmlFor="eventTitle">Subiect sedinta</label>
                <input
                  required="required"
                  name='title'
                  type="text"
                  className="form-control"
                  id="eventTitle"
                  value={solutionName}
                  disabled
                  style={{ cursor: 'not-allowed', backgroundColor: '#e9ecef' }}
                />
              </div>

              {/* Email */}
              <div className="form-group">
                <label htmlFor="email">Email-ul dvs.</label>
                <input
                  required="required"
                  name='email'
                  type="email"
                  className="form-control"
                  id="email"
                  value={formData.email}
                  onChange={(e) => setFormData({
                    ...formData,
                    email: e.target.value
                  })}
                />
              </div>

              {/* Description (optional) */}
              <div className="form-group">
                <label htmlFor="eventDescription">Descriere (optional)</label>
                <input
                  type="text"
                  name='eventDescription'
                  className="form-control"
                  id="eventDescription"
                  value={formData.description}
                  onChange={(e) => setFormData({
                    ...formData,
                    description: e.target.value
                  })}
                />
              </div>

              {/* Location (optional) */}
              {/* <div className="form-group">
                <label htmlFor="eventLocation">Locatie (optional)</label>
                <input
                  name="location"
                  type="text"
                  className="form-control"
                  id="eventLocation"
                  value={formData.location}
                  onChange={(e) => setFormData({
                    ...formData,
                    location: e.target.value
                  })}
                />
              </div> */}


              <div className="form-group">
                <label htmlFor="eventDates"><h4>Data si ora sedinta</h4></label>
                <div className="form-group" id="eventDates">
                  {/* Duration */}
                  <label htmlFor="durations-wrapper">Durata</label>
                  <div className="form-group" id="durations-wrapper">
                    <div id="durations">
                      {durations.map((duration, index) => {
                        // if current duration is the selected one
                        // highlight it
                        const selectedDurationHighlighting = (`${duration}-min` === formData.duration.type ? "highlight-duration" : "")
                        // const isCustomDuration = 'custom' === formData.duration.type
                        return <div key={index}>
                          <input type="hidden" name='eventDuration' value={duration} />
                          <label type='button' className={"duration-btn " + selectedDurationHighlighting} onClick={() => handleDuration(duration)}>{`${duration} min`}</label>
                        </div>
                      })}
                      {/* Custom Duration */}
                      <div>
                        <button type='button' className={isCustomDuration ? "duration-btn highlight-duration" : "duration-btn"} onClick={() => handleDuration(0, "custom")}>Custom</button>

                      </div>
                    </div>
                    {/* Custom Duration Hide/Show */}
                    {isCustomDuration ? <><div className="form-group" id="custom-duration">
                      <input
                        type="number"
                        value={formData.duration.value}
                        max={40}
                        onChange={e =>
                          setFormData({
                            ...formData, duration: {
                              ...formData.duration,
                              value: e.target.value
                            }
                          })
                        }
                      />
                      <select>
                        <option value={"minute"}>Minut(e)</option>
                        {/* <option value={"hour"}>Ora</option> */}
                      </select>
                    </div></> : null}
                  </div>

                  <MyDragAndDropCalendar setFormData={setFormData} formData={formData} />
                </div>
              </div>
            </form>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} type='button'>
            Inchide
          </Button>
          <Button variant="primary" onClick={handleSubmit} type="submit">
            Trimite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CalendarModal

