import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
//import responsive from './slider-resonsive'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class PersonalizedAppsSolutions extends Component {
  render() {
    const settings = {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      accessibility: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return (
      <>
        <Slider
          {...settings}
          className="img-carousel-dots-nav btn-style-1 icon-2"
        >
          {this.props.data.map((item, id) => (
            <div className="item" key={id}>
              <div className="dlab-box project-bx">
                <div className="dlab-media radius-sm dlab-img-overlay1 dlab-img-effect zoom">
                  <Link to={item.link}>
                    <img src={item.image} alt="" />
                  </Link>
                </div>
                <div className="dlab-info">
                  <h4 className="dlab-title">
                    <Link to={item.link}>{item.projectName}</Link>
                  </h4>
                  <Link to={item.link}>
                    <button
                      className="btn"
                      type="button"
                      style={{
                        width: "100%",
                        backgroundColor: "var(--th-secondary-color)",
                        color: "var(--th-primary-color)",
                      }}
                    >
                      Vezi detalli
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default PersonalizedAppsSolutions
