import React from "react"

import {
  AppsSolutionsContent,
  homeBannerSliderContent,
  solutiiContent,
} from "../../content/homepage/homeContent"

import Testimonials from "../../markup/element/Testimonials"
import Partners from "../../markup/element/Partners/Partners"
import PersonalizedAppsSolutions from "../../markup/element/PersonalizedAppsSolutions"
import SolutiiDemoSlider from "../../markup/element/solutiiDemoSlider"
import HomeBannerSlider from "../../markup/element/HomeBannerSlider"

import NoutatiCard from "./NoutatiCard"

import SolutiiSlider from "../../markup/element/th-sliders/thSolutiiHomeSlider"
import ThIndustriiHomeSlider from "../../markup/element/th-sliders/ThIndustriiHomeSlider"
import { industriesHome } from "../../content/homepage/homeContent"
import "./home.css"
import "../../css/style.css"
import "../../css/homeslider.css"
import "../../css/responsive.css"
import "../../css/plugins.css"
import "../../css/skin/skin-4.css"
import "../../css/templete.min.css"
import "../../css/slider-animations.css"

import { PartenersSection } from "../index"


const bg = require("../../images/background/Group-27.png").default

const Home = () => {
  return (
    <>
      <HomeBannerSlider
        data={homeBannerSliderContent}
        contentWrapperCls="sliderStyle4"
        btnCls="site-button"
        btnCls2="site-button outline outline-2 white m-r15 th-btn-banner-transparent"
        btnText="Despre noi"
        btn2Text="Serviciile noastre"
        show2Btn
      />

      <div className="bg-white">
        <div className="content-block">
          <div className="bg-white mt-0">
            <div className="content-block">
              <SolutiileNoastreSection />
              <IndustriiSection />
              <SolutiiAplicatiiSection />
              <SolutiiDemoSection />
              {/* TODO: Hide noutati section until there's content for it */}
              <NoutatiSection />
              {/* <TestimonialeSection /> */}
              <PartenersSection />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SolutiileNoastreSection = () => (
  <div
    className="section-full content-inner bg-white wow fadeIn th-solutiile-noastre_wrapper"
    data-wow-duration="2s"
    data-wow-delay="0.4s"
  >
    <div className="container">
      <div className="th-solutii">
        <h1
          className="th-solutii-title"
          style={{
            textAlign: "center",
            marginTop: 40,
            fontWeight: 800,
          }}
        >
          Solutiile Noastre
        </h1>
      </div>
      <div className="on-show-slider">
        <SolutiiSlider />
      </div>
    </div>
  </div>
)

const IndustriiSection = () => (
  <div className="section-full bg-white content-inner">
    <div className="container">
      <h1
        style={{
          textAlign: "center",
          color: "#000000",
          fontWeight: 900,
        }}
      >
        Industrii
      </h1>
      <ThIndustriiHomeSlider industries={industriesHome} />
    </div>
  </div>
)

const SolutiiAplicatiiSection = () => (
  <div
    className="section-full content-inner bg-img-fix overlay-black-dark wow fadeIn"
    data-wow-duration="2s"
    data-wow-delay="0.8s"
    style={{ backgroundImage: "url(" + bg + ")" }}
  >
    <div className="container">
      <div className="section-head text-white text-center">
        <h2 className="title">
          Solutii hardware & software pentru aplicatii personalizate
        </h2>
        <p>
          Echipa noastra personalizeaza si imbunatateste continuu portofoliul nostru de solutii existente.
        </p>
      </div>
      <div className="row icon-white">
        <div className="col-lg-12 col-md-12">
          <PersonalizedAppsSolutions data={AppsSolutionsContent} />
        </div>
      </div>
    </div>
  </div>
)

const SolutiiDemoSection = () => (
  <div
    className="section-full content-inner bg-gray wow fadeIn th-solutii-demo__wrapper"
    data-wow-duration="2s"
    data-wow-delay="0.6s"
  >
    <div className="container">
      <div className="section-head text-black text-center">
        <h2 className="title" style={{ fontWeight: 900 }}>
          Solutii Demo
        </h2>
        <p>
          Daca demonstratiile interactive pregatite de noi nu sunt edificatoare, nu ezitati sa ne contactati pentru clarificari si eventual pregatirea unor demonstratii adecvate cerintelor afacerii dumneavoastra.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="col-lg-12 col-md-12">
            <SolutiiDemoSlider data={solutiiContent} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const NoutatiSection = () => (
  <div className="section-full content-inner bg-white th-noutati-section_wrapper">
    <div className="container">
      <div className="section-head text-black text-center">
        <h2
          className="title text-capitalize"
          style={{ fontWeight: 900, fontSize: 40 }}
        >
          Noutati
        </h2>
        <p>
          Departamentul de Cercetare, Inovare si Dezvoltare lucreaza continuu pentru imbunatatirea solutiilor
          existente precum si inovarea unor noi solutii adaptate cerintelor clientilor nostri.
        </p>
      </div>
      <div className="row" style={{ justifyContent: "center" }}>
        <NoutatiCard />
      </div>
    </div>
  </div>
)

const TestimonialeSection = () => (
  <div
    className="section-full content-inner bg-gray wow fadeIn th-testimoniale-section"
    data-wow-duration="2s"
    data-wow-delay="0.3s"
  >
    <div className="container">
      <div className="section-head text-black text-center">
        <h2 className="title" style={{ fontWeight: 900 }}>
          Testimoniale
        </h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry has been the industry's standard dummy text ever since the
          been when an unknown printer.
        </p>
      </div>
      <Testimonials />
    </div>
  </div>
)

// const ParteneriSection = () => (
//   <div
//     className="section-full content-inner bg-white wow fadeIn th-parteneri__home"
//     data-wow-duration="2s"
//     data-wow-delay="0.6s"
//   >
//     <div className="container">
//       <div className="section-head text-black text-center">
//         <h2 className="title text-capitalize" style={{ fontWeight: 900 }}>
//           Parteneri
//         </h2>
//       </div>
//       <Partners />
//     </div>
//   </div>
// )

export default Home
